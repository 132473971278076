import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import title from '../assets/title.png';

const Invite: React.FC = () => {
    const { path } = useParams();

    return (
        <div className="App-header">
            <img src={title} className="smallTitle" alt="Title" />
            <div style={{position: 'absolute', top: '25%'}}>
                <a href={`byefornow://invite/${path}`}>
                    <button className="store-button">Click to invite if not automatic</button>
                </a>
            </div>
            <div style={{position: 'absolute', top: '68.5%'}}>
                <text>Don't have the app?</text>
            </div>
            <div style={{position: 'absolute', top: '75%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <a href="https://apps.apple.com/us/app/monogatari/id6502987623">
                    <button className="store-button">Download on the App Store</button>
                </a>
                <a href="https://play.google.com/store/apps/details?id=org.monogatari.project">
                    <button className="store-button">Get it on Google Play</button>
                </a>
            </div>
        </div>
    );
};
  
export default Invite;